import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"
import styled from "styled-components"
import { ChipList, StyledChipList } from "../Chip/ChipList"
import { ScreenReaderText } from "../ScreenReaderText"

const StyledCard = styled.article``

const StyledCardHeader = styled.div`
  ${StyledChipList} {
    margin: -1.5rem 1rem -2.5rem;
    position: relative;
  }
`

const StyledCardHeaderLink = styled(Link)`
  display: inline-block;
`

const StyledCardContent = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 6px rgb(58 75 85 / 6%), 0 1px 3px rgb(58 75 85 / 6%);

  margin: ${({ hasFeaturedImage }) =>
    hasFeaturedImage ? "-2rem 0.5rem 0.5rem" : "0 0.5rem 0.5rem"};
  padding: ${({ hasFeaturedImage }) =>
    hasFeaturedImage ? "4rem 1rem 1rem" : "1rem"};

  h2 {
    color: inherit;
  }

  h2 > a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
      color: var(--color-navy);
    }
  }
`

const StyledReadMoreLink = styled(Link)`
  color: var(--color-navy);
  text-decoration: none;
  border-bottom: 2px solid var(--color-cameo);
  padding-bottom: 4px;
  transition: all 250ms ease-in;
  &:hover,
  &:focus {
    border-color: var(--color-navy);
    padding-bottom: 2px;
  }
`

const options = {
  replace: ({ attribs }) => attribs && attribs.class === "more-link" && <></>,
}

function ArticleCard({
  id,
  title,
  uri,
  databaseId,
  tags,
  excerpt,
  featuredImage,
}) {
  const uuid = `article-${databaseId}`
  const img = getImage(featuredImage?.node?.localFile)

  return (
    <StyledCard id={uuid}>
      {img && (
        <StyledCardHeader>
          <StyledCardHeaderLink to={uri} aria-labelledby={`${uuid}-title`}>
            <GatsbyImage
              style={{
                boxShadow:
                  "0 4px 14px rgb(58 75 85 / 8%), 0 3px 6px rgb(58 75 85 / 6%)",
                borderRadius: "0.25rem",
                display: "block",
              }}
              image={img}
              alt={featuredImage.node.altText}
            />
          </StyledCardHeaderLink>
          <ChipList labels={tags.nodes} />
        </StyledCardHeader>
      )}
      <StyledCardContent hasFeaturedImage={!!img}>
        <h2 id={`${uuid}-title`}>
          <Link to={uri}>{title}</Link>
        </h2>
        {parse(excerpt, options)}
        <StyledReadMoreLink to={uri}>
          Read more <ScreenReaderText>about {title}</ScreenReaderText>
        </StyledReadMoreLink>
      </StyledCardContent>
    </StyledCard>
  )
}

export default ArticleCard
