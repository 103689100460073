import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Chip } from "./Chip"

export const StyledChipList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 2rem 0;
`

export function ChipList({ labels }) {
  if (!labels?.length) {
    return null
  }

  return (
    <StyledChipList>
      {React.Children.toArray(
        labels.map(({ id, name, uri }) => {
          return (
            <li>
              <Chip uri={uri}>{name}</Chip>
            </li>
          )
        })
      )}
    </StyledChipList>
  )
}

ChipList.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      uri: PropTypes.string,
    })
  ),
}

ChipList.defaultProps = {
  labels: [],
}
