import PropTypes from "prop-types"
import * as React from "react"
import styled from "styled-components"
import SiteLayout from "./SiteLayout"

const ArchiveWrapper = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  max-width: 1180px;

  padding: 0 1rem;
  @media only screen and (min-width: 64em) {
    padding: 0 2rem;
  }

  > main {
    margin: 4rem 0;
  }
`

const ArchiveLayout = ({ children }) => {
  return (
    <SiteLayout>
      <ArchiveWrapper>
        <main>{children}</main>
      </ArchiveWrapper>
    </SiteLayout>
  )
}

ArchiveLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ArchiveLayout
