import { graphql } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import ArticleCard from "../components/Cards/ArticleCard"
import Pagination from "../components/Pagination/Pagination"
import Seo from "../components/seo"
import ArchiveLayout from "../layouts/ArchiveLayout"

const Grid = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
`

function TagArchive({
  data: { allWpPost, wpTag },
  pageContext: { nextPagePath, previousPagePath },
}) {
  const { nodes: posts } = allWpPost
  const { name } = wpTag

  return (
    <ArchiveLayout>
      <Seo
        title={wpTag.seo.title}
        description={wpTag.seo.metaDesc}
        twitterTitle={wpTag.seo.twitterTitle}
        twitterDescription={wpTag.seo.twitterDescription}
        twitterImage={wpTag.seo.twitterImage}
        opengraphTitle={wpTag.seo.opengraphTitle}
        opengraphDescription={wpTag.seo.opengraphDescription}
        opengraphType={wpTag.seo.opengraphType}
        opengraphImage={wpTag.seo.opengraphImage}
        schema={wpTag.seo.schema}
        metaRobotsNoindex={wpTag.seo.metaRobotsNoindex}
        metaRobotsNofollow={wpTag.seo.metaRobotsNofollow}
        canonical={wpTag.seo.canonical}
      />
      <h1>{name}</h1>
      <Grid>
        {posts.map(post => (
          <ArticleCard key={post.id} {...post} />
        ))}
      </Grid>
      <Pagination
        nextPagePath={nextPagePath}
        previousPagePath={previousPagePath}
      />
    </ArchiveLayout>
  )
}

export default TagArchive

export const pageQuery = graphql`
  query BlogPostByTagId(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $offset: Int!
    $postsPerPage: Int!
    $tagId: String!
  ) {
    wpTag(id: { eq: $tagId }) {
      name
      description
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        schema {
          raw
        }
      }
    }
    # selecting all posts by Tag id
    allWpPost(
      filter: { tags: { nodes: { elemMatch: { id: { eq: $tagId } } } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
            date
          }
        }
        id
        uri
        title
        databaseId
        tags {
          nodes {
            id
            name
            slug
            uri
          }
        }
      }
    }
  }
`
