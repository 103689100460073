import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { ScreenReaderText } from "../ScreenReaderText"

const StyledPagination = styled.nav`
  margin: 2.5rem 0;
`

const StyledPaginationList = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  li:not(:first-child) {
    margin-left: 1rem;
  }
`

const StyledPaginationLink = styled(Link)`
  padding: 0.5rem 0.75rem;
  border: 1px solid #e1e4e8;
  text-decoration: none;
  color: var(--color-wild-blue);
  font-weight: 600;
  border-radius: 3px;
  display: inline-block;
  &:hover,
  &:focus {
    background-color: #eff3f6;
  }
`

function Pagination({ previousPagePath, nextPagePath }) {
  return (
    <StyledPagination aria-label="pagination">
      <StyledPaginationList>
        {previousPagePath && (
          <li>
            <StyledPaginationLink to={previousPagePath}>
              Previous <ScreenReaderText>page</ScreenReaderText>
            </StyledPaginationLink>
          </li>
        )}
        {nextPagePath && (
          <li>
            <StyledPaginationLink to={nextPagePath}>
              Next <ScreenReaderText>page</ScreenReaderText>
            </StyledPaginationLink>
          </li>
        )}
      </StyledPaginationList>
    </StyledPagination>
  )
}

Pagination.propTypes = {
  previousPagePath: PropTypes.string,
  nextPagePath: PropTypes.string,
}

export default Pagination
