import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

export const StyledChip = styled(Link)`
  border: none;
  display: inline-block;
  padding: 0.25em 0.75em;
  background-color: var(--color-cool-gray);
  border-radius: 20px;
  box-shadow: none;
  transition: all 150ms ease;
  font-size: 1rem;
  font-weight: var(--font-bold);
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-gray);
    outline: none;
  }

  &:hover {
    transform: translate3d(0, -1px, 0);
  }

  &:focus,
  &:active {
    transform: translate3d(0, 1px, 0);
  }
`

export function Chip({ children, uri }) {
  return <StyledChip to={uri}>{children}</StyledChip>
}

Chip.defaultProps = {
  uri: "",
}

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  uri: PropTypes.string.isRequired,
}
